/*
import React from "react";
import Layout from "../components/layout";
import Home from "../components/templates/Home";

export default function index() {
  return (
    <Layout>
      <Home />
    </Layout>
  );
}
*/
import React from "react";
import Layout from "../components/layout";
import PublicTransport from "../components/templates/PublicTransport";

export default function index() {
  return (
    <Layout>
      <PublicTransport />
    </Layout>
  );
}
